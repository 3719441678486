<script lang="ts">
export default {
  name: "ViewHeader"
}
</script>

<template>
  <div class="view-nav">
    <el-menu mode="horizontal" :ellipsis="false" :router="true" :default-active="$route.path">
      <div id="nav-banner">
        <router-link to="/">iceroki.com</router-link>
      </div>
      <el-menu-item v-for="route in routes" :key="route.meta.index" :index="route.path">
        {{ route.meta.title }}
      </el-menu-item>
      <div style="width: 40px"></div>
    </el-menu>
  </div>
</template>

<script lang="ts" setup>
import { ElMenu, ElMenuItem } from "element-plus";
import { routes } from "@/router";
</script>

<style lang="scss" scoped>
#nav-banner {
  flex-grow: 1;
  font-weight: bolder;
  line-height: var(--el-header-height);
  font-size: 27px;
  text-indent: 4rem;
  * {
    text-decoration: none;
    color: var(--el-menu-text-color);
  }
}
</style>