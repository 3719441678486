import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      index: 0,
      title: "首页"
    }
  },
  {
    path: "/code",
    name: "code",
    component: () => import("@/views/CodeView.vue"),
    meta: {
      index: 1,
      title: "编码"
    }
  },
  {
    path: "/game",
    name: "game",
    component: () => import("@/views/GameView.vue"),
    meta: {
      index: 2,
      title: "游戏"
    }
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
    meta: {
      index: 3,
      title: "关于"
    }
  }
];

const paths = routes.map(route => {
  return route.path;
});

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router;

export { paths, routes };