<script lang="ts">
export default {
  name: "App"
};
</script>

<template>
  <el-container id="wrapper" @mousewheel="handleWheel">
    <el-header id="header">
      <view-header />
    </el-header>
    <el-main id="animation">
      <router-view v-slot="{ Component }">
        <transition :name="transitionName">
          <component :is="Component"/>
        </transition>
      </router-view>
    </el-main>
  </el-container>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { paths } from "@/router";
import _ from "lodash";
import ViewHeader from "@/components/ViewHeader.vue";
import { ElContainer, ElHeader, ElMain } from "element-plus";

const transitionName = ref();
const router = useRouter();
const index = ref(0);

router.beforeEach((to: any, from: any) => {
  index.value = to.meta.index;
  if (to.meta.index > from.meta.index) {
    transitionName.value = "slide-down";
  } else if (to.meta.index < from.meta.index) {
    transitionName.value = "slide-up";
  } else {
    transitionName.value = "";
  }
});

const handleWheel = _.throttle((e: any) => {
  if (e.deltaY > 0) {
    if (index.value < paths.length - 1) {
      router.push(paths[++index.value]);
    }
  } else {
    if (index.value > 0) {
      router.push(paths[--index.value]);
    }
  }
}, 800);
</script>

<style lang="scss" scoped>
#wrapper {
  width: 100%;
  height: 100%;
  #header {
    z-index: 255;
  }
  #animation {
    display: flex;
    flex-direction: column;
    height: 200%;
    margin-top: -80px;
    & > * {
      height: 100%;
      width: 100%;
      background-color: var(--el-color-info-light-9);
    }

    .slide-up-enter-active,
    .slide-up-leave-active,
    .slide-down-enter-active,
    .slide-down-leave-active {
      transition: transform 800ms;
      position: absolute;
    }

    .slide-down-enter-from {
      transform: translateY(100%);
    }

    .slide-down-enter-to {
      transform: translateY(0);
    }

    .slide-down-leave-from {
      transform: translateY(0);
    }

    .slide-down-leave-to {
      transform: translateY(-100%);
    }

    .slide-up-enter-from {
      transform: translateY(-100%);
    }

    .slide-up-enter-to {
      transform: translateY(0);
    }

    .slide-up-leave-from {
      transform: translateY(0);
    }

    .slide-up-leave-to {
      transform: translateY(100%);
    }
  }
}
</style>